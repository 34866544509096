<template>
	<div class="userexplain">
		<div class="userexplain_top">
			<div class="userexplain_mybreadcrumb">
				当前位置： <app-mybreadcrumb />
			</div>

			<!-- <div class="userexplain_inp">
				<el-input placeholder="输入关键字搜索" suffix-icon="el-icon-search" v-model="keyword"></el-input>
			</div> -->
		</div>

		<section class="userexplain_content">
			<div class="userexplain_list"  v-if='flag == 1'>
				<div class="userexplain_item" v-for='item in explainList'>
					<div class="userexplain_message" :title='item.name'>{{item.name}}</div>
					<div class="userexplain_time" @click='downFile(item.id)'>点击下载</div>
				</div>

				<!-- 分页 -->
				<div class="userexplain_list_reportscreen">
					<div class="userexplain_list_footer">
				        <div class="userexplain_list_mar">
				        	<app-mypaginations :pagSize='paginations' :allData='explainList' @setHandleCurrent='handleCurrent' @setHandleSize='handleSize' @setPage='setPage' @setSize='setSize' />
				        </div>
			        </div>
				</div>
			</div>

			<el-empty description="数据为空" v-if='flag == 0' :image-size="200"></el-empty>
		</section>
	</div>
</template>

<script>
	import MyBreadcrumb from '@/components/MyBreadcrumb/mybreadcrumb'
	import MyPaginations from '@/components/MyPaginations/mypaginations.vue'

	export default {
		data() {
			return {
				paginations: {
		          page_index: 1, // 当前位于哪页
		          total: 10, // 总数
		          page_size: 10, // 1页显示多少条
		          page_sizes: [10, 20, 50, 90], //每页显示多少条
		          layout: "total, sizes, prev, pager, next, jumper" // 翻页属性
		        },
		        count: 0,
				explainList: [],
				listsItem: [],
				flag: 1
			}
		},
		components: {
			'app-mybreadcrumb': MyBreadcrumb,
			'app-mypaginations': MyPaginations
		},
		methods: {
			downFile(id) {
				let url = `${this.baseUrl}/download_file?id=${id}`
				location.href = url
			},
			setPage(data) {
				this.paginations.page_index = data

				this.getData()
			},
			setSize(data) {
				this.paginations.page_index = 1
				this.paginations.page_size = data

	            this.getData()
			},
			handleCurrent(item) {
		    	this.listsItem = item
		    },
		    handleSize(item) {
		    	this.listsItem = item
		    },
		    //重置分页
		    resetPaginations() {
		      // 总页数
		      this.paginations.total = this.count
		      // 设置默认分页数据
		      this.listsItem = this.explainList.filter((item, index) => {
		        return index < this.paginations.page_size
		      })
		    },
		    getData() {
		    	let data = {
		    		page: this.paginations.page_index,
		    		limit: this.paginations.page_size,
		    		token: localStorage.eleToken ? localStorage.eleToken : ''
		    	}

		    	this.$post('/instructions_lst', data).then(res => {
		    		if(!res.data.result) {
						this.explainList = []
						this.count = 0
						
						this.flag = 0
					}else {
						this.explainList = res.data.result.length == 0 ? [] : res.data.result
						this.count = res.data.count
						
						this.flag = 1
					}

					this.resetPaginations()
		    	})
		    }
		},
		mounted() {
			this.getData()
		}
	}
</script>

<style scoped>
	@import './userexplain.css';
</style>